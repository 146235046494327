<template>
  <fragment>
    <td :class="classNames" :colspan="headers.length">
      <div class="d-flex align-center">
        <v-btn icon @click="toggle">
          <v-icon :class="classNames" v-if="!isOpen">mdi-chevron-down</v-icon>
          <v-icon :class="classNames" v-else>mdi-chevron-up</v-icon>
        </v-btn>
        <h4 class="text-xs-subtitle-1 text-md-h6 d-inline-block" v-text="classes[group].headerText" />
      </div>
    </td>
    <!-- <div v-else :class="classNames">
      <div class="d-flex align-center">
        <v-btn icon @click="toggle">
          <v-icon :class="classNames" v-if="!isOpen">mdi-chevron-down</v-icon>
          <v-icon :class="classNames" v-else>mdi-chevron-up</v-icon>
        </v-btn>
        <h4 class="text-subtitle-1 d-inline-block" v-text="classes[group].headerText" />
      </div>
    </div> -->
  </fragment>
</template>

<script>
export default {
  name: 'GroupHeader',
  props: {
    headers: {
      required: true,
      type: Array
    },
    group: {
      required: true
    },
    isOpen: {
      type: Boolean,
      default: () => true
    },
    toggle: {
      type: Function
    }
  },
  computed: {
    classNames () {
      let out = []
      out.push(this.$data.classes[parseInt(this.group)].cls.toLowerCase())
      return out
    }
  },
  data () {
    return ({
      classes: [{
        cls: 'gold',
        headerText: 'Gold TLB Package Qualifiers (1-20)'
      }, {
        cls: 'silver',
        headerText: 'Silver TLB Package Qualifiers (21-50)'
      }, {
        cls: 'bronze',
        headerText: 'Bronze TLB Package Qualifiers (51-100)'
      }, {
        cls: 'unclassified',
        headerText: 'Unclassified'
      }],
    })
  }
}
</script>

<style scoped>
.gold {
  background-color: gold;
  color: black;
}

.silver {
  background-color: silver;
  color: black;
}

.bronze {
  background-color: #b08d57;
  color: white;
}

.unclassified {
  background-color: black;
}
</style>
