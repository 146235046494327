<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
    <v-footer>
      <div>
        <p><small>PokerBROS is not a sponsor of or in any way involved with this promotional activity, nor does PokerBROS endorse it.</small></p>
        <p><small>PokerBROS is an online social gaming platform and does not provide any real money service.</small></p>
      </div>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
