<template>
  <fragment>
    <v-menu
      v-if="isOnPC"
      ref="menu"
      :close-on-content-click="false"
      min-width="auto"
      :return-value.sync="internalValue"
      v-model="menuOpen"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          v-model="displayValue"
          readonly
          append-icon="mdi-chevron-down"
          label="Month"
          v-on="on"
          v-bind="attrs"
        >
          <template #append-outer>
            <v-btn
              @click="useCurrent"
              color="accent"
              small
              :icon="!isOnPC"
            >
              <v-icon small left>mdi-calendar-today</v-icon>
              <span>Current</span>
            </v-btn>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        type="month"
        min="2022-04"
        :max="maxMonth"
        no-title
        scrollable
        offset-y
        v-model="internalValue"
      >
        <v-btn @click="menuOpen = false" text color="primary">Cancel</v-btn>
        <v-btn @click="$refs.menu.save(internalValue)" text color="primary">OK</v-btn>
      </v-date-picker>
    </v-menu>
    <v-dialog
      v-else
      ref="dialog"
      v-model="menuOpen"
      :return-value.sync="internalValue"
      width="290px"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          v-model="displayValue"
          readonly
          append-icon="mdi-chevron-down"
          label="Month"
          v-on="on"
          v-bind="attrs"
        >
          <template #append-outer>
            <v-btn small icon @click="useCurrent"><v-icon>mdi-calendar-today</v-icon></v-btn>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        type="month"
        min="2022-04"
        :max="maxMonth"
        no-title
        scrollable
        offset-y
        v-model="internalValue"
      >
        <v-btn @click="menuOpen = false" text color="primary">Cancel</v-btn>
        <v-btn @click="$refs.dialog.save(internalValue)" text color="primary">OK</v-btn>
      </v-date-picker>
    </v-dialog>
  </fragment>
</template>

<script>
import dayjs from "dayjs"

export default {
  name: 'MonthPicker',
  props: {
    value: {
      type: String,
      default: () => dayjs(new Date()).format('YYYY-MM')
    },
    isCurrent: {
      type: Boolean,
      default: () => true
    }
  },
  computed: {
    maxMonth: () => dayjs(new Date()).format('YYYY-MM'),
    displayValue () {
      if (this.isCurrent) return 'Current'
      return dayjs(this.internalValue).format('MMMM YYYY')
    }
  },
  data () {
    return ({
      menuOpen: false,
      internalValue: this.value
    })
  },
  methods: {
    useCurrent () {
      this.$emit('reset')
    }
  },
  watch: {
    internalValue (v) {
      this.$emit('input', v)
    }
  }
}
</script>
