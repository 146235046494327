<template>
  <fragment>
    <div style="text-align: center">
      <v-img
        contain
        src="@/assets/logo.png"
        height="350px"
        style="margin-top: -25px"
      />
    </div>
    <h1 style="margin-top: -50px" class="text-center primary--text text-md-h3 text-sm-h4 mb-4">MTT Leaderboard Rankings</h1>
    <ScoreTable
      gSheetId="1hPYBs-Amy6NwrV4jJ9MlUm1qyIguwjHtcCY1eoE3kwk"
    />
  </fragment>
</template>

<script>
import ScoreTable from '@/components/ScoreTable/ScoreTable.vue';

// import HelloWorld from '../components/HelloWorld'
export default {
  name: 'HomeView',
  components: {
    ScoreTable
  }
}
</script>
