<template>
  <v-data-table
    :items="tableContents"
    :loading="isFetching"
    :headers="headers"
    :custom-filter="searchFunction"
    group-by="class"
    sort-by="POINTS"
    sort-desc
    :items-per-page="100"
    :dense="!isOnPC"
    :search="search"
    :caption="`Last updated: ${lastUpdate}`"
    :mobile-breakpoint="0"
  >
    <template #top>
      <v-toolbar class="justify-space-between align-bottom pt-3">
        <v-text-field
          label="Search"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
        />
        <v-spacer />
        <MonthPicker
          v-model="month"
          :isCurrent="monthPickerIsCurrent"
          @reset="monthPickerIsCurrent = true"
          @input="monthPickerIsCurrent = false"
        />
      </v-toolbar>
    </template>
    <template #[`header.prize`]>
      <div class="text-center">
        Rank <span :class="!isOnPC && 'd-block'">&amp;</span> <span class="green--text text--lighten-2">Prize</span>
      </div>
    </template>
    <template #[`item.prize`]="{ item, value }">
      <span :class="isOnPC ? 'mr-3' : 'd-block'" class="text-body-2">{{ item.RANK }}</span>
      <span :class="isOnPC && 'text-body-2'" class="font-weight-bold green--text text--lighten-2">{{ value }}</span>
    </template>
    <template #[`item.playerName`]="{ item, value }">
      <span :class="!isOnPC && 'd-block'" class="text-body-2 text--disabled mr-3">{{ item.playerId }}</span>
      <span>{{ value }}</span>
    </template>
    <template #[`group.header`]="{ group, toggle, isOpen }">
      <GroupHeader
        :toggle="toggle"
        :group="group"
        :isOpen="isOpen"
        :headers="headers"
      />
    </template>
  </v-data-table>
</template>

<script>
import dayjs from "dayjs";
import _ from "lodash"
import GroupHeader from "./GroupHeader.vue"
import MonthPicker from "./MonthPicker.vue";

export default {
  name: "ScoreTable",
  props: {
    gSheetId: {
      required: true,
      type: String
    },
    range: {
      type: String,
      default: () => "B3:I99999"
    }
  },
  computed: {
    headers () {
      return [
        {
          text: "Rank & Prize",
          value: "prize",
          filterable: false,
          align: 'center',
          width: this.isOnPC ? '10%' : '1%'
        },
        // {
        //   text: "Prize",
        //   value: "PRIZE",
        //   width: '1%'
        // },
        {
          text: "Player",
          value: "playerName",
          ...(!this.isOnPC && { width: '1%' })
        },
        // {
        //   text: "Player ID",
        //   value: "playerId"
        // },
        {
          text: "Club Name",
          value: "CLUB NAME",
          ...(!this.isOnPC && { width: '1%' })
        },
        {
          text: this.tableContents?.[0]?.['TOTAL TOP 10'] ? 'Total Top 10' : 'Points',
          value: this.tableContents?.[0]?.['TOTAL TOP 10'] ? 'TOTAL TOP 10' : 'POINTS',
          filterable: false,
          align: "end"
        }
      ]
    },
    gSheetAPIKey () {
      return process.env.VUE_APP_GOOGLE_API_KEY;
    },
    sheet () {
      return this.$data.monthPickerIsCurrent ? 'CURRENT' : dayjs(this.$data.month).format("MMM YYYY")
    },
    tableContents() {
      if (!this.$data.fetchedItems?.length)
        return [];
      const clone = _.clone(this.$data.fetchedItems.filter(v => v.length > 0));
      const keys = clone.splice(0, 1)[0].map(k => k.toUpperCase());

      let out = clone.map(v => {
        const row = {};
        keys.forEach((key, n) => {
          if (n === 2) return
          switch (key) {
            case "PRIZE":
              row.prize = v[n]
              break

            case "POINTS":
            case "TOTAL TOP 10":
              row[key] = parseFloat(v[n]).toFixed(2)
              break;
            
            case "PLAYER NAME":
              row.playerName = v[n]
              break;
            
            // Player name and ID has to be transformed to allow
            // Column merging using VDataTable's `item` slot
            case "PLAYER ID":
              row.playerId = v[n]
              break
            
            case "RANK":
              row[key] = parseInt(v[n]);
              break;
            
            default:
              row[key] = v[n]
          }
        });
        return row;
      });
      out = out
        .map(row => ({
          ...row,
          class: row.RANK <= 20 ? 0 : row.RANK <= 50 ? 1 : row.RANK <= 100 ? 2 : 3
        }))
        .sort((a, b) => {
          if (a.RANK > b.RANK) return 1
          if (a.RANK < b.RANK) return -1
          return 0
        });
      return out;
    }
  },
  data() {
    return {
      isFetching: false,
      search: "",
      fetchedItems: [],
      monthPickerIsCurrent: true,
      month: dayjs(new Date()).format('YYYY-MM'),
      lastUpdate: ''
    };
  },
  methods: {
    searchFunction (value, search, item) {
      if (search == null) return false
      const searchRegex = new RegExp(search.toLowerCase().replace(' ', '.+'))
      
      const {
        "CLUB NAME": clubName,
        playerId,
        playerName
      } = item

      return (
        searchRegex.test(clubName?.toString().toLowerCase() ?? "") ||
        searchRegex.test(playerName?.toString().toLowerCase() ?? "") ||
        searchRegex.test(playerId?.toString() ?? "")
      )
    },
    fetchLastUpdate (sheet) {
      // function convertGSheetDate (gSheetDate) {
      //   return dayjs((gSheetDate - 25569) * 24 * 60 * 60 * 1000).format('MMMM DD')
      // }

      console.log('[url]', `https://sheets.googleapis.com/v4/spreadsheets/${this.gSheetId}/values/${sheet.toUpperCase() === 'CURRENT' ? dayjs(new Date()).format('MMM%20YYYY') : `${sheet}`}!I2:J2`)

      const REQUEST_URL = new URL(`https://sheets.googleapis.com/v4/spreadsheets/${this.gSheetId}/values/${sheet.toUpperCase() === 'CURRENT' ? dayjs(new Date()).format('MMM%20YYYY') : `${sheet}`}!I2:J2`)
      REQUEST_URL.searchParams.append("key", this.gSheetAPIKey);
      // REQUEST_URL.searchParams.append("valueRenderOption", "UNFORMATTED_VALUE");
      return new Promise((resolve, reject) => {
        window.fetch(REQUEST_URL)
          .then(response => response.json())
          .then(data => {
            const row = data.values?.[0]
            console.log(row[1])
            const resolution = row[1]
            // let resolution = row?.[0] && row?.[0]?.length ? row[0] : row?.[1]
            // resolution = isNaN(resolution) ? resolution : convertGSheetDate(resolution)
            resolve(resolution)
          })
          .catch(e => { reject(e) })
      })
    },
    fetchFromSheets (sheet) {
      const REQUEST_URL = new URL(`https://sheets.googleapis.com/v4/spreadsheets/${this.gSheetId}/values/${sheet.toUpperCase() === 'CURRENT' ? dayjs(new Date()).format('MMM YYYY') : `${sheet}`}!${this.range}`);
      REQUEST_URL.searchParams.append("key", this.gSheetAPIKey);
      REQUEST_URL.searchParams.append("valueRenderOption", "UNFORMATTED_VALUE");
      return new Promise((resolve, reject) => {
        window.fetch(REQUEST_URL)
          .then(response => response.json())
          .then(data => {
            resolve(data.values);
          })
          .catch(e => { reject(e); });
        });
    }
  },
  watch: {
    async sheet (v) {
      this.$data.isFetching = true;
      try {
        [this.$data.fetchedItems, this.$data.lastUpdate] = await Promise.all([
          this.fetchFromSheets(v), this.fetchLastUpdate(v)
        ])
      }
      catch (e) {
        console.error(e);
      }
      finally {
        this.$data.isFetching = false;
      }
    }
  },
  async mounted () {
    this.$data.isFetching = true;
    try {
      [this.$data.fetchedItems, this.$data.lastUpdate] = await Promise.all([
        this.fetchFromSheets(this.sheet), this.fetchLastUpdate(this.sheet)
      ])
    }
    catch (e) {
      console.error(e);
    }
    finally {
      this.$data.isFetching = false;
    }
  },
  components: { GroupHeader, MonthPicker }
}
</script>
